import React from 'react'
import { combine } from 'effector'
import { useStore } from 'effector-react'
import s from '../../login.module.scss'
import { ErrorBlock } from '../error_components/ErrorBlock'
import {
  $form,
  $id,
  $registrationError,
  $errorWG0065,
} from '/models/registration'
import { $loginError } from '/models/login'
import { VerifyAccount } from '/components/login/components/forms/VerifyAccount'
import { SignIn } from '/components/login/components/forms/SignIn'
import { Login } from '/components/login/Login'
import { SignUp } from '/components/login/components/forms/SignUp'
import { FORM_TYPE } from '/utils/constants'

const $error = combine(
  $registrationError,
  $loginError,
  (regError, loginError) => regError || loginError || null
)

export const FormBox = (): JSX.Element => {
  const form = useStore($form)
  const id = useStore($id)
  const error = useStore($error)
  const errorWG0065 = useStore($errorWG0065)

  return (
    <>
      {/* hack for old Login form */}
      {form === FORM_TYPE.LOGIN ? (
        <Login />
      ) : (
        <div className={s.loginWrap}>
          {id ? (
            <VerifyAccount id={id} />
          ) : form === FORM_TYPE.SIGN_IN ? (
            <SignIn />
          ) : form === FORM_TYPE.SIGN_UP ? (
            <SignUp />
          ) : null}

          {/* hack for old Login form */}
          {error && !errorWG0065 && form !== FORM_TYPE.LOGIN && (
            <ErrorBlock error={error} />
          )}
        </div>
      )}
    </>
  )
}
