import axios from 'axios'
import { domain, guard, combine, forward } from '.'
import { repeatRequest } from '/utils/helpers'
import { FORM_TYPE, REGISTER_STATUS } from '/utils/constants'
import { ErrorI } from '/components/login/interfaces'

const api = '/api/v3/subscribers'

const { createEvent, createEffect, createStore } = domain('register')

interface RegisterBody {
  email: string
  password: string
  // firstName: string
  // lastName: string
  // phoneNumber: string
  // address: string
  // city: string
  // zipCode: string
  // country: string
  // state: string
  // timeZone: string
}

// *
// * events
// *

export const setForm = createEvent<string>()
export const doRegister = createEvent<RegisterBody>()
export const doConfirm = createEvent<{ id: string; code: string }>()
export const resendCode = createEvent<{ id: string }>()
export const resetError = createEvent()

// *
// * effects
// *

export const registerAllowedFx = createEffect(() =>
  axios.get(`${api}/register/allowed`)
)

export const registerFx = createEffect<RegisterBody, any>((data) =>
  axios.post(`${api}/register`, data)
)

export const registerConfirmFx = createEffect<
  { id: string; code: string },
  any
>((body) => axios.post(`${api}/register/confirm`, body))

export const registerStatusFx = createEffect<string, any>((id) =>
  axios.get(`${api}/register/${id}`)
)

export const resendCodeFx = createEffect<{ id: string }, any>((id) =>
  axios.post(`${api}/register/resend`, id)
)

export const checkRegisterStatus = createEffect<string, any>((id) =>
  registerStatusFx(id).then((response) => {
    const status = response?.data?.payload?.status

    if (
      status === REGISTER_STATUS.IN_PROCESS ||
      status === REGISTER_STATUS.WAITING_EMAIL_CONFIRMATION
    ) {
      return repeatRequest(() => checkRegisterStatus(id), 1000)
    }
  })
)

// *
// * stores
// *

export const $form = createStore<string | null>(null)
  .on(registerAllowedFx.done, () => FORM_TYPE.SIGN_IN)
  .on(registerAllowedFx.failData, (_, { response }: any) => {
    if (response?.data?.errorCode !== 'WG0062') return FORM_TYPE.LOGIN
  })
  .on(setForm, (_, form) => form)

export const $registerStatus = createStore<ErrorI | null>(null)
  .on(registerStatusFx.doneData, (_, { data }: any) => ({
    code: data?.errorCode,
    payload: data?.payload,
    message: data?.message,
  }))
  .reset([setForm])

export const $registrationDone = $registerStatus.map(
  (status) => status?.payload?.status === REGISTER_STATUS.DONE
)

export const registrationFailed = $registerStatus.map((status) => {
  const stat = status?.payload?.status
  return stat === REGISTER_STATUS.FAILED || stat === REGISTER_STATUS.EXPIRED
    ? status
    : null
})

export const $id = createStore<string | null>(null)
  .on(registerFx.doneData, (_, response) => {
    return response?.data?.payload?.id || ''
  })
  .reset([$registrationDone, registrationFailed])

export const $registrationError = createStore<ErrorI | null>(null)
  .on(
    [
      registerAllowedFx.failData,
      registerFx.failData,
      registerConfirmFx.failData,
      registerStatusFx.failData,
      resendCodeFx.failData,
    ],
    (_, { response }: any) => ({
      code: response?.data?.errorCode,
      payload: response?.data?.payload,
      message: response?.data?.message,
    })
  )
  .reset([
    registerAllowedFx,
    registerFx,
    registerConfirmFx,
    registerStatusFx,
    resendCodeFx,
    resetError,
  ])

export const geoBlock = $registrationError.map((error) =>
  error?.code === 'WG0062' ? error : null
)

export const $errorWG0065 = $registrationError.map((error) =>
  error?.code === 'WG0065' ? error : null
)

export const $permanentDisabling = createStore(false).on(
  resendCodeFx.failData,
  (_, { response }: any) => response?.data?.errorCode === 'WG0067'
)

// Counter
const SECONDS = 60
const decreaseSeconds = createEvent()

export const $seconds = createStore(0)
  .on([registerFx.doneData, resendCodeFx.doneData], () => SECONDS)
  .on($errorWG0065, (state, error) => error?.payload?.remainsSeconds || state)
  .on(decreaseSeconds, (state) => state - 1)

export const $counterInOperation = $seconds.map(Boolean)

const intervalFx = createEffect(
  () => new Promise((resolve) => setTimeout(resolve, 1000))
)

guard({
  source: $seconds,
  filter: Boolean,
  target: intervalFx,
})

forward({
  from: intervalFx.done,
  to: decreaseSeconds,
})

// *
// * connections
// *

guard({
  source: doRegister,
  filter: registerFx.pending.map((is) => !is),
  target: registerFx,
})

forward({
  from: doConfirm,
  to: registerConfirmFx,
})

guard({
  clock: registerConfirmFx.done,
  filter: (id) => checkRegisterStatus(id),
  source: $id,
})

guard({
  source: resendCode,
  filter: resendCodeFx.pending.map((is) => !is),
  target: resendCodeFx,
})
