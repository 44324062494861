import React from 'react'
import { useStore } from 'effector-react'
import { $seconds } from '/models/registration'

import s from '/components/login/login.module.scss'

export const Counter = (): JSX.Element => (
  <p className={`${s.message} ${s.counter}`}>
    Request new code in {useStore($seconds)} sec
  </p>
)
