import React, { useRef, useState } from 'react'
import { doLogin } from '/models/login'
import { setForm } from '/models/registration'
import { Logo } from './FormLogo'
import { Button } from '/components/common/button'
import { ErrorBlock } from '../error_components/ErrorBlock'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLockAlt, faUserCircle } from '@fortawesome/pro-solid-svg-icons'
import { FORM_TYPE, VALIDATION_ERROR_MESSAGE } from '/utils/constants'
import { capitalizeFirstLetter } from '/utils/helpers'

import s from '../../login.module.scss'

export const SignIn = (): JSX.Element => {
  const [fieldError, setError] = useState('')
  const emailRef = useRef<HTMLInputElement>(null)
  const passRef = useRef<HTMLInputElement>(null)

  const validate = (field) => {
    // do one validation for two forms
    if (field.validity?.valueMissing) {
      const message = VALIDATION_ERROR_MESSAGE.FIELD_IS_REQUIRED.replace(
        '{{field}}',
        field.name
      )
      setError(capitalizeFirstLetter(message))
      return false
    }
    if (field.validity?.patternMismatch) {
      if (field.name === 'email')
        setError(VALIDATION_ERROR_MESSAGE.EMAIL_PATTERN_MISMATCH)
      if (field.name === 'password')
        setError(VALIDATION_ERROR_MESSAGE.PASSWORD_PATTERN_MISMATCH)
      return false
    }
    if (field.validity?.tooLong && field.name === 'password') {
      setError(VALIDATION_ERROR_MESSAGE.PASSWORD_TOO_LONG)
      return false
    }
    return true
  }

  const submit = (e) => {
    setError('')
    e.preventDefault()
    const email = emailRef?.current
    const password = passRef?.current

    if (email && password) {
      const validPass = validate(password)
      const validEmail = validate(email)

      if (validPass && validEmail) {
        doLogin({
          email: email.value,
          password: password.value,
        })
      }
    }
  }

  return (
    <>
      <div className={s.loginBox}>
        <div className={s.loginHeader}>
          <Logo />
          <h1 className={s.loginTitle}>Welcome, Dear Friend!</h1>
        </div>
        <form className={s.loginForm} onSubmit={submit} noValidate>
          <div className={s.loginForm__field}>
            <FontAwesomeIcon
              className={s.loginForm__icon}
              icon={faUserCircle}
            />
            <div className={s.loginForm__label}>
              <input
                required
                ref={emailRef}
                id='email'
                name='email'
                type='email'
                pattern='.+@.+\..+$'
                className={`${s.loginForm__input} ${
                  emailRef?.current?.validity?.valid === false ? s.invalid : ''
                }`}
                placeholder={' '}
              />
              <label htmlFor='email'>Email</label>
            </div>
          </div>
          <div className={s.loginForm__field}>
            <FontAwesomeIcon className={s.loginForm__icon} icon={faLockAlt} />
            <div className={s.loginForm__label}>
              <input
                required
                ref={passRef}
                id='password'
                name='password'
                type='password'
                maxLength={64}
                pattern='^[A-Za-z0-9@#$_&-*!?.]+$'
                className={`${s.loginForm__input} ${
                  passRef?.current?.validity?.valid === false ? s.invalid : ''
                }`}
                placeholder={' '}
              />
              <label htmlFor='password'>Password</label>
            </div>
          </div>
          <Button
            type='submit'
            title='Sign In'
            className={`${s.button} button-primary button-small button-full-width`}
          />
          <p className={`${s.message} ${s.messageMarginTop}`}>
            Don`t have an account?
          </p>
          <Button
            type='button'
            title='Sign Up'
            className={`${s.button} button-ghost button-small button-full-width`}
            handleClick={() => setForm(FORM_TYPE.SIGN_UP)}
          />
        </form>
      </div>
      {fieldError ? <ErrorBlock error={{ message: fieldError }} /> : null}
    </>
  )
}
