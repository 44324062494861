import React, { useEffect, useRef, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, navigate, RouteComponentProps, useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Player } from '/components/common/player/Player'
import { _ } from '/config'
import { ContentType } from '../../home/constants/index'
import { actions as vodActions } from '/components/vod/store'
import {
  getVodCategory,
  getVodSubCategory,
} from '/components/vod/store/selectors'
import { humanizeTime } from '/utils/date'

import './styles.scss'
import { PlayerPreloader } from '~/components/common/Preloaders'
import { Button } from '~/components/common/button'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVideo } from '@fortawesome/pro-solid-svg-icons'
import { faHeart } from '@fortawesome/pro-regular-svg-icons'
import { Popup } from '~/components/common/popups'
import { faTimes } from '@fortawesome/pro-light-svg-icons'
import { faHeart as faHeartSolid } from '@fortawesome/pro-solid-svg-icons'
import { GEOBLOCK_MESSAGE } from '/utils/constants'
import { useStore } from 'effector-react'
import { $backendVersion } from '/models/account'

interface Props extends RouteComponentProps, ConnectedProps<typeof connector> {
  vodId: string // from router
}

function VodDetails(props: Props) {
  const {
    vodId,
    getVODById,
    movie,
    currentCategory,
    setSearchContent,
    currentSubCategory,
    setActiveMovieUrl,
    setActiveMovie,
    setIsVodFavorite,
  } = props
  const { t } = useTranslation()
  const pageUrl = useLocation()
  const backendVersion = useStore($backendVersion)

  useEffect(() => {
    pageUrl.pathname.includes('//') && navigate('/not_found')
    getVODById(+vodId).then((response) => {
      return response.error && navigate('/not_found')
    })

    return () => {
      setSearchContent([])
      setActiveMovieUrl('')
    }
  }, [])

  const [isModal, setIsModal] = useState(false)
  useEffect(() => {
    const video = document.querySelector('video')
    if (video) !isModal ? video.play() : video.pause()
  }, [isModal])
  const closeModal = () =>
    document.fullscreenElement ? document.exitFullscreen() : setIsModal(false)

  const updateIsMovieFavorite = (movie) => {
    setActiveMovie({ ...movie, favorite: !movie.favorite })
    setIsVodFavorite(movie)
  }
  return movie ? (
    <div className={`player-page `}>
      <div className='breadcrumbs'>
        <Link to={'/vods'}>{_('breadcrumbs-movies')}</Link>
        {currentCategory && (
          <Link to={`/vods/categories/${currentCategory?.id}`}>
            {currentCategory.name === 'All' ? t('All') : currentCategory.name}
          </Link>
        )}
        {currentSubCategory && (
          <Link
            to={`/vods/categories/${currentCategory?.id}/subs/${currentSubCategory.id}`}
          >
            {currentSubCategory.name}
          </Link>
        )}
        <span>{movie.name}</span>
      </div>

      <div className='player-page__content'>
        <div className='player-page__player'>
          {movie?.blockedByAcl ? (
            <div className='player-page__info-box'>
              <h1 className='player-page__title'>{t('common:Sorry')}!</h1>
              <p className='player-page__message'>
                {t(`common:message.${GEOBLOCK_MESSAGE}`, {
                  item: `"${movie.name}"`,
                })}
              </p>
            </div>
          ) : movie.url ? (
            <Player
              src={movie.url}
              skin='vod'
              stoppedTime={movie.stoppedTime}
              contentId={movie.id}
              contentType={ContentType.VOD}
              contentTitle={movie.name}
              contentLive={false}
            />
          ) : (
            <PlayerPreloader />
          )}
        </div>

        <div className='player-page__info info'>
          <div className='moovie-player__title'>
            <div className='movie-player__description_wrapper'>
              <div className='info__title'>{movie.name}</div>
              <p className='short-info'>
                {movie.ageRating ? <span>{movie.ageRating}</span> : null}
                {movie.resolution ? (
                  <span>
                    {movie.resolution.substring(
                      movie.resolution.length,
                      movie.resolution.length - 2
                    )}
                  </span>
                ) : null}
                {movie.year ? <span>{movie.year}</span> : null}
                {movie.length && movie.length !== '00:00:00' ? (
                  <span>{humanizeTime(movie.length)}</span>
                ) : null}
              </p>
            </div>
            <div className={'moovie-buttons'}>
              {movie.trailerUrl && (
                <Button
                  title={
                    <>
                      <FontAwesomeIcon icon={faVideo} />{' '}
                      <span className='button__text'>{t('Trailer')}</span>
                    </>
                  }
                  className={'button-chips button-ghost'}
                  handleClick={() => {
                    setIsModal(true)
                  }}
                />
              )}
              {backendVersion[0] >= '1.42' ? (
                <Button
                  title={
                    <>
                      <FontAwesomeIcon
                        icon={movie.favorite ? faHeartSolid : faHeart}
                      />
                      <span className='button__text'>
                        {t(`${movie.favorite ? '' : 'Add to'} Favorite`)}
                      </span>
                    </>
                  }
                  className={'button-chips button-ghost'}
                  handleClick={() => updateIsMovieFavorite(movie)}
                />
              ) : null}
            </div>
          </div>

          {movie.directors ? (
            <>
              <h3 className='info__subtitle'>{t('vod:Directors')}</h3>
              <p className='info__paragraph'>{movie.directors}</p>
            </>
          ) : null}
          {movie.stars ? (
            <>
              <h3 className='info__subtitle'>{t('vod:Stars')}</h3>
              <p className='info__paragraph'>{movie.stars}</p>
            </>
          ) : null}
          {movie.description ? (
            <>
              <h3 className='info__subtitle'>{t('vod:Description')}</h3>
              <p className='info__paragraph'>{movie.description}</p>
            </>
          ) : null}
        </div>
      </div>
      {movie.trailerUrl
        ? isModal && (
            <Popup className={'trailer-popup'}>
              <Player
                skin={'vod'}
                src={movie.trailerUrl}
                contentType={ContentType.VOD}
              >
                <Button
                  className={'close-player__button'}
                  title={<FontAwesomeIcon icon={faTimes} />}
                  handleClick={closeModal}
                />
              </Player>
            </Popup>
          )
        : null}
    </div>
  ) : null
}

function mapStateToProps(state, props) {
  return {
    movie: state.views.vod.activeMovie,
    currentCategory: getVodCategory(state, props),
    currentSubCategory: getVodSubCategory(state, props),
  }
}

const mapDispatchToProps = {
  getVODById: vodActions.getVODById,
  setSearchContent: vodActions.setSearchContent,
  setActiveMovieUrl: vodActions.setActiveMovieUrl,
  setIsVodFavorite: vodActions.setIsVodFavorite,
  setActiveMovie: vodActions.setActiveMovie,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const VodDetailsPage = connector(VodDetails)
