import React, { useCallback, useEffect, useState } from 'react'
import { connect, ConnectedProps } from 'react-redux'
import { Link, useNavigate, useLocation } from '@reach/router'
import { useTranslation } from 'react-i18next'
import { Carousel } from '/components/common/carousel/Carousel'
import { getHorizontalVODLogo } from '/components/vod/helpers'
import { HorizontalCard } from '/components/common/cards/horizontal-card/HorizontalCard'
import { Grid } from '/components/common/grid-cards-layout'
import { VodsSearchBarContainer } from '../VodsSearchBar'
import { NoContent } from '/components/common/no-content'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faSearch } from '@fortawesome/pro-regular-svg-icons'
import { _ } from '/config'

import { Movie, MovieCategory } from '~/components/vod/interfaces'

import {
  getRecursiveVodCategory,
  getVodContent,
  getVodContentMovies,
  getVodContentProps,
  getVodsSearch,
  getVodSubCategory,
  getSubContentByCategory,
  getSubContentByCategorySubs,
  getSubContentByCategoryPagination,
} from '/components/vod/store/selectors'
import { actions as vodActions } from '/components/vod/store'
import { IntersectionObserverTarget } from '/components/common/IntersectionObserverTarget'
import { setModal } from '/components/common/modal/model'
import { GEOBLOCK_MESSAGE } from '/utils/constants'

type PropsFromRedux = ConnectedProps<typeof connector>

interface Props extends PropsFromRedux {
  categoryId: string // from router
  subCategoryId?: string // from router
}

export const VodCategory = (props: Props) => {
  const {
    categoryId,
    currentCategory,
    currentSubCategory,
    subCategoryId,
    subCategories,
    loadedSubContentByCategory,
    pagination,
    movies,
    settings,
    content,
    search,
    getSubCategoriesAndVods,
    loadContent,
    updateContent,
    setIsVodFavorite,
  } = props
  const navigate = useNavigate()
  const location = useLocation()
  const [q, setQ] = useState<string>('')
  const { t } = useTranslation()

  const maxLength = 36

  const load = useCallback(() => {
    if (pagination.page < pagination.totalPages - 1) {
      getSubCategoriesAndVods({
        categoryId: +categoryId,
        page: pagination.page + 1,
        count: 5,
      })
    }
  }, [pagination])

  useEffect(() => {
    if (
      !loadedSubContentByCategory &&
      currentCategory?.subCategories?.length &&
      !subCategoryId
    ) {
      getSubCategoriesAndVods({
        categoryId: currentCategory.id,
        page: 0,
        count: 5,
      })
    }
  }, [currentCategory])

  useEffect(() => {
    location.pathname.includes('//') && navigate('/not_found')
    if (!movies.length) {
      loadContent({
        page: 0,
        categoryId: Number(subCategoryId || categoryId),
        count: maxLength,
      }).then((response) => {
        return response.error && navigate('/not_found')
      })
    }
  }, [])

  const handleMovieClick = useCallback(
    (movie: Movie, category?: MovieCategory) => {
      if (movie?.blockedByAcl) {
        setModal({
          text: t(`common:message.${GEOBLOCK_MESSAGE}`, {
            item: `"${movie.name}"`,
          }),
        })
        return
      }

      let url = `${location.pathname}/${movie?.id}`

      if (category && !category.subCategories)
        url = `${location.pathname}/subs/${category.id}/${movie?.id}`

      return navigate(url)
    },
    [movies]
  )

  const handleCategoryClick = useCallback(
    (id: number) => () => {
      let url = `/vods/categories/${categoryId}`
      if (id) url += `/subs/${id}`

      return navigate(url)
    },
    [categoryId, subCategoryId]
  )

  const getItemsByPage = useCallback(
    (id, page) => {
      updateContent({
        categoryId: Number(subCategoryId || id),
        page,
        count: maxLength,
      })
    },
    [settings]
  )

  return (
    <>
      <div className='page-search-breadcrumbs-wrapper'>
        <div className='breadcrumbs'>
          <Link to={'/vods'}>{t(_('breadcrumbs-movies'))}</Link>
          {currentCategory && (
            <Link to={`/vods/categories/${currentCategory?.id}`}>
              {currentCategory.name === 'All' ? t('All') : currentCategory.name}
            </Link>
          )}
          {currentSubCategory && (
            <Link
              to={`/vods/categories/${currentCategory?.id}/subs/${currentSubCategory.id}`}
            >
              {currentSubCategory.name}
            </Link>
          )}
        </div>
        <VodsSearchBarContainer callback={setQ} />
      </div>
      {q ? (
        search && search.length ? (
          <Grid
            id={0}
            page={{ number: 0, last: true }}
            getItemsByPage={() => null}
            title={t('Search Result ({{length}})', { length: search.length })}
            className={'base-cards'}
          >
            {search.map((item) => {
              const imageUrl = item.imageHorizontalUrl
                ? getHorizontalVODLogo(item.imageHorizontalUrl)
                : getHorizontalVODLogo(item.imageUrl)
              return (
                <HorizontalCard
                  favorite={item.favorite}
                  handleFavoriteClick={() => setIsVodFavorite(item)}
                  key={`${item.id}${item.name}`}
                  title={item.name}
                  imageUrl={imageUrl || ''}
                  handleClick={() => handleMovieClick(item)}
                />
              )
            })}
          </Grid>
        ) : (
          <NoContent
            icon={<FontAwesomeIcon icon={faSearch} className='icon' />}
            title={t('No search results found')}
          />
        )
      ) : currentCategory?.subCategories?.length && !subCategoryId ? (
        <>
          {subCategories?.map((category: MovieCategory) => (
            <Carousel
              id={`vod_${category.id}`}
              key={category.id}
              carouselTitle={category.name}
              showAllOption={
                content?.[category.id]?.props?.totalElements > maxLength
              }
              handleCategoryClick={handleCategoryClick(category.id)}
            >
              {(content[category.id]?.movies || [])
                .slice(0, maxLength)
                .map((item) => {
                  const imageUrl = item.imageHorizontalUrl
                    ? getHorizontalVODLogo(item.imageHorizontalUrl)
                    : getHorizontalVODLogo(item.imageUrl)

                  return (
                    <HorizontalCard
                      favorite={item.favorite}
                      handleFavoriteClick={() => setIsVodFavorite(item)}
                      key={`${item.id}${item.name}`}
                      title={item.name}
                      imageUrl={imageUrl || ''}
                      handleClick={() => handleMovieClick(item, category)}
                    />
                  )
                })}
            </Carousel>
          ))}
          {pagination && (
            <IntersectionObserverTarget
              load={load}
              page={pagination?.page || 0}
            />
          )}
        </>
      ) : (
        settings && (
          <Grid
            id={Number(subCategoryId || categoryId) || 0}
            contextSelector={`vod_${subCategoryId || categoryId || 'all'}`}
            page={{ number: settings.curNumber, last: settings.last }}
            getItemsByPage={getItemsByPage}
            title={
              currentCategory?.name === 'All'
                ? t('All')
                : currentSubCategory?.name || currentCategory?.name
            }
            className={'base-cards'}
          >
            {movies?.map((item) => {
              const imageUrl = item.imageHorizontalUrl
                ? getHorizontalVODLogo(item.imageHorizontalUrl)
                : getHorizontalVODLogo(item.imageUrl)
              return (
                <HorizontalCard
                  favorite={item.favorite}
                  handleFavoriteClick={() => setIsVodFavorite(item)}
                  key={`${item.id}${item.name}`}
                  title={item.name}
                  imageUrl={imageUrl || ''}
                  handleClick={() => handleMovieClick(item)}
                />
              )
            })}
          </Grid>
        )
      )}
    </>
  )
}

function mapStateToProps(state, { categoryId, subCategoryId }) {
  return {
    loadedSubContentByCategory: getSubContentByCategory(state, { categoryId }),
    subCategories: getSubContentByCategorySubs(state, { categoryId }),
    pagination: getSubContentByCategoryPagination(state, { categoryId }),
    movies: getVodContentMovies(state, {
      categoryId: subCategoryId || categoryId,
    }),
    settings: getVodContentProps(state, {
      categoryId: subCategoryId || categoryId,
    }),
    content: getVodContent(state),
    currentCategory: getRecursiveVodCategory(state, { categoryId }),
    currentSubCategory: getVodSubCategory(state, { categoryId, subCategoryId }),
    search: getVodsSearch(state),
  }
}

const mapDispatchToProps = {
  getSubCategoriesAndVods: vodActions.getSubCategoriesAndVods,
  loadContent: vodActions.getVodsByCategory,
  updateContent: vodActions.updateVodsByCategory,
  setSearchContent: vodActions.setSearchContent,
  setIsVodFavorite: vodActions.setIsVodFavorite,
}

const connector = connect(mapStateToProps, mapDispatchToProps)

export const VodCategoryPage = connector(VodCategory)
