import React, { useEffect, useState } from 'react'

const Image = ({
  imageUrl,
  defaultUrl,
  className,
  secondaryImageUrl,
}: {
  imageUrl: string
  defaultUrl: string
  secondaryImageUrl?: string
  className?: string
}) => {
  const [logoUrl, setLogoUrl] = useState<string | null>()

  const loadImage = (url) => {
    const img = new window.Image()
    img.addEventListener('error', () => {
      url === imageUrl
        ? loadImage(secondaryImageUrl || defaultUrl)
        : url === secondaryImageUrl && loadImage(defaultUrl)
    })
    img.addEventListener('load', () => setLogoUrl(url))

    img.src = url
  }

  useEffect(() => {
    loadImage(imageUrl)
  }, [])

  return logoUrl ? (
    logoUrl !== defaultUrl ? (
      <img src={logoUrl} className={className || ''} />
    ) : (
      <div className={'no-content-image'}>
        <img src={logoUrl} />
      </div>
    )
  ) : (
    <></>
  )
}

export default Image
